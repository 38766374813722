// Load画面を出す
document.addEventListener("turbo:before-fetch-request", loadingFrame);
function loadingFrame(e) {
  var $ = window.$;
  var ele = e.target;
  var frame = ele.closest("turbo-frame");
  // 親がturbo-frameの時だけ
  if (frame) {
    console.log("move in frame");
    var name = frame.getAttribute("data-inner");
    var $inner = $(name);
    if ($inner.length) {
      var $loading = $("<div></div>");
      var $anim = $("<div></div>");
      $anim
        .addClass("flex items-center justify-center")
        .html('<div class="dot-flashing"></div>');
      $loading
        .addClass(
          "absolute inset-0 bg-white bg-opacity-90 z-[100] flex items-center justify-center"
        )
        .html($anim);
      $inner.addClass("relative");
      // 上に白をかぶせる
      $inner.append($loading);
    }
  }
}
